import { useRoutes } from 'react-router-dom';

import routes from 'Routes';

import 'styles/App.scss'

function App() {
    const content = useRoutes(routes);

    return (
        content
    );
}

export default App;
