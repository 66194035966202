import gql from "graphql-tag";

export const GET_DEVELOPMENT_TYPE = gql`
  query GetTechnologyType {
    team_builder_Hierarchy_Type_dict {
      type_id, type_nm
    }
  }
`;

export const GET_BUSINESS_DOMAINS = gql`
query getBusinessDomain{
  team_builder_Business_domain_dict{
    domain_id
    domain_nm
  }
}
`;