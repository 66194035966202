import { useTeam } from "context/TeamContext";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { appSteps } from "variables/app_steps";

import './Footer.scss';

const Footer: FC = () => {
    const { step, setContactUsModalVisible } = useTeam()
    const navigate = useNavigate();
    const back = `${appSteps[appSteps.indexOf(step) - 1]}`
    const next = `${appSteps[appSteps.indexOf(step) + 1]}`

    return (
        <footer className="footer-project fixed">
            <div>
                <span>Need help?</span>
                <button onClick={() => setContactUsModalVisible(true)} className="button-green">Contact us</button>
            </div>
            <div>
                {back && <button onClick={() => navigate(back)} className="button-white">Back</button>}
                <button onClick={() => navigate(next)} className="button-orange">Next</button>
            </div>
        </footer>
    )
};

export default Footer;
