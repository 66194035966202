import { ReactNode } from 'react';
import './Tag.scss'

interface IProps {
    title: string | ReactNode;
    color?: string;
    fontColor?: string;
    className?: string;
    onClick?: any;
}

const Tag = ({ title, color, fontColor, className, onClick }: IProps) => {
    return (
        <span onClick={onClick} className={`tag ${className && className}`} style={{ background: `${color}`, color: `${fontColor}` }}>
            {title}
        </span>
    )
}

export default Tag;