import gql from "graphql-tag";

export const INSERT_PROJECT_HIERARCHY_NEW = gql`
mutation Project_Hierarchy_NEW ($projectHierarchy: [team_builder_Project_Hierarchy_NEW_insert_input!]!) {
  insert_team_builder_Project_Hierarchy_NEW (objects: $projectHierarchy){
    returning {
      id
    }
  }}
`;

export const SET_PROJECT_HIERARCHY_COUNT = gql`
mutation MyMutation2($id: uuid!, $required_number: Int) {
  update_team_builder_Project_Hierarchy_by_pk(pk_columns: {id: $id}, _set: {required_number: $required_number}) {
    required_number
    id
  }
}
`

export const GET_PROJECT_HIERARCHY_NEW = gql`
query Project_Hierarchy_NEW ($project_id: uuid!) {
  team_builder_Project_Hierarchy_NEW (where: {project_id: {_eq: $project_id}}){
    id
    role_id
    parent_role_id
    required_number
    Role_dict{
      role_nm
    }
    Cart_Order_NEWs(where: {Order_status_dict: {status_nm: {_eq: "CART"}}}){
      project_hierarchy_id
      cart_id
      employee_id
      Employee{
        employee_id
        employee_first_nm
        employee_second_nm
        specialization
        Employee_Grade_Roles{
          Grade_Role_matrix{
            Grade_dict{
              grade_nm
            }
            Role_dict{
              role_nm
            }
            price
          }
        }
        Emp_experiences{
          project_nm
          responsibilities
          tech_stack
          start_dt
          end_dt
          role_nm
          client
        }
      }
    }
  }
}
`;

export const DELETE_PROJECT_HIERARCHY_NEW = gql`
mutation delete_Project_Hierarchy_NEW ($project_id: uuid!) {
  delete_team_builder_Project_Hierarchy_NEW (where: {project_id: {_eq: $project_id}}){
    affected_rows
}
}
`;

export const DELETE_PROJECT_HIERARCHY_WITH_CART = gql`
mutation deleteProjectHierarchyWithCart($project_hierarchy_id: uuid!) {
  delete_team_builder_Cart_Order_NEW(where: {project_hierarchy_id: {_eq: $project_hierarchy_id}}) {
    numb_rows_cart: affected_rows
  }

  delete_team_builder_Project_Hierarchy_NEW(where: {id:{_eq: $project_hierarchy_id}}) {
    numb_rows_proj_hierarchy: affected_rows
  }
}
`;

export const UPSERT_PROJECT_HIERARCHY = gql`
mutation upsert_Project_Hierarchy_New ($project_hierarchy: [team_builder_Project_Hierarchy_NEW_insert_input!]!) {
  insert_team_builder_Project_Hierarchy_NEW (objects: $project_hierarchy,
    on_conflict: {
      constraint: Project_Hierarchy_NEW_pkey,
      update_columns: [
        parent_role_id, 
      	role_id,
      	required_number]
    }
  ) {
    returning {
      id
    }
  }
}
`;