import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useUserId } from '@nhost/react';
import { useQuery } from '@apollo/client';
import { Layout } from 'antd';
import { useTeam } from 'context/TeamContext';

import { GET_USER_QUERY } from 'gql/users';
import { GET_COMPANY } from 'gql/company';
import Header from 'components/Header';
import AuthorizationModal from 'components/AuthorizationModal';
import { DevelopmentPage } from 'pages/DevelopmentPage';

export const BaseLayout = () => {
    const id = useUserId();
    const { setUser, setCompany, isAuthenticated } = useTeam();

    const { data: userData } = useQuery(GET_USER_QUERY, {
        variables: { id },
    });
    const { data: teamBuilderCompany } = useQuery(GET_COMPANY, {
        variables: {
           user_id: id,
        },
     });

    useEffect(() => {
        setUser(userData?.user)
    }, [userData, isAuthenticated, setUser]);

    useEffect(() => {
        if (teamBuilderCompany?.team_builder_Company[0]?.company_id) {
           setCompany({
              company_nm: teamBuilderCompany?.team_builder_Company[0]?.company_nm, 
              company_id: teamBuilderCompany?.team_builder_Company[0]?.company_id 
           });
        }
     }, [setCompany, teamBuilderCompany]);

    if (window.screen.width < 768) {
        return (
            <DevelopmentPage />
        )
    }

    return (
        <>
            <Layout>
                <Header />
                <AuthorizationModal />
                <Outlet />
            </Layout>
        </>
    );
};

