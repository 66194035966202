import { useAuthenticationStatus } from '@nhost/react';
import { Navigate, useLocation } from 'react-router-dom';
import SuspenseLoader from './SuspenseLoader';

const ProtectedRoute = ({ children }: any) => {
    const { isAuthenticated, isLoading } = useAuthenticationStatus();
    const location = useLocation();

    if (isLoading) {
        return (
            <SuspenseLoader />
        )
    }

    if (!isAuthenticated) {
        return <Navigate to="/sign-in" state={{ from: location }} replace />
    }

    return children;
}

export default ProtectedRoute;
