import { Divider } from "antd";

import { useTeam } from "context/TeamContext";


const DefaultOrder = () => {
    const { arrNodesTeam } = useTeam();

    return (
        <>
            {arrNodesTeam.map((node) => 
                <div key={node.role_id} className="teamOrder-item">
                    <div className="item-info">
                        <p>{node.name}</p>
                        <span>{node.count || 0}</span>
                    </div>
                    <Divider />
                </div>
            )}
        </>
    )
};

export default DefaultOrder;
