import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'components/SuspenseLoader';
import ProtectedRoute from 'components/ProtectedRoute';

// Layouts
import { BaseLayout } from 'layouts/BaseLayout';
import SidebarLayout from 'layouts/SidebarLayout';

import ScrollToTop from 'utils/scrollToTop';
import { PrivacyStatement } from 'components/PrivacyStatement';

const Loader = (Component: any) => (props: any) =>
(
    <Suspense fallback={
    <div style={{
        position: 'fixed',
        zIndex: 100,
        height: `100vh`,
        width: `100vw`,
        top: '80px',
        left: '0px',
    }}>
        <SuspenseLoader />
    </div>
    }>
        <Component {...props} />
    </Suspense>
);

// Pages

const LandingPage = Loader(lazy(() => import('pages/Landing')));
const CreateProject = Loader(lazy(() => import('pages/CreateProject')));
const TeamStructure = Loader(lazy(() => import('pages/TeamStructure')));
const RequestToOutstaff = Loader(lazy(() => import('pages/RequestToOutstaff')));
const AcceptedOrder = Loader(lazy(() => import('pages/AcceptedOrder')));
const RequestContact = Loader(lazy(() => import('pages/RequestContact')));
const Orders = Loader(lazy(() => import('pages/Orders')));
const Employee = Loader(lazy(() => import('pages/EmployeePage')));
const OrderView = Loader(lazy(() => import('pages/OrderView')));
const EmployeesLibrary = Loader(lazy(() => import('pages/EmployeesLibrary')));
const Error404 = Loader(lazy(() => import('pages/errors/error404/Error404')));
const Error500 = Loader(lazy(() => import('pages/errors/error500/Error500')));

export const routes = [
    {
        path: '',
        element: <Navigate to='/landing' replace />
    },
    {
        path: '/',
        element: <BaseLayout />,
        children: [
            {
                path: 'landing',
                element: <LandingPage />,
            },
            {
                path: '',
                element: <SidebarLayout />,
                children: [
                    {
                        path: '/',
                        element: <Navigate to='/project' replace />,
                    },
                    {
                        path: 'project',
                        element:
                            <>
                                <ScrollToTop />
                                <CreateProject />
                            </>,

                    },
                    {
                        path: 'simple-project',
                        element: 
                            <>
                                <ScrollToTop />
                                <CreateProject simple={true} />
                            </>,

                    },
                    {
                        path: '/structure',
                        element: (
                            <ProtectedRoute>
                                <TeamStructure />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: '/order',
                        element: (
                            <ProtectedRoute>
                                <RequestToOutstaff />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: '/request/:number',
                        element: (
                            <RequestContact />
                        ),
                    },
                    {
                        path: '/order/:number',
                        element: (
                            <ProtectedRoute>
                                <AcceptedOrder />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: '/employees',
                        element: (
                            <ProtectedRoute>
                                <EmployeesLibrary />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: '/employees/:employee_id',
                        element: (
                            <ProtectedRoute>
                                <ScrollToTop />
                                <Employee />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: 'orders',
                        element: (
                            <ProtectedRoute>
                                <Orders />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: 'orders/:project_id',
                        element: (
                            <ProtectedRoute>
                                <OrderView />
                            </ProtectedRoute>
                        ),
                    },
                ],
            },
            {
                path: 'landing/privacy-statement',
                element: <PrivacyStatement />
            },
            {
                path: '/error500',
                element: <Error500 />
            },
            {
                path: '*',
                element: <Error404 />
            }
        ],
    }
];

export default routes;
