import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { Col, Modal, Row } from 'antd';
import { CREATE_ORDER_NUMBER, GET_CART, GET_LAST_ORDER, INSERT_TO_CART, UPDATE_STATUS_CART } from 'gql/cart';
import { useTeam } from 'context/TeamContext';

import AboutProject from 'components/AboutProject';
import ContactInfo from 'components/ContactInfo';
import TeamOrder from 'components/TeamOrder';
import OrderTotalAmount from 'components/OrderTotalAmount';
import { AgreementModal } from 'components/AgreementModal';

import { ReactComponent as Close } from 'assets/icons/close.svg';

import './ContactUsModal.scss';
import { CART_STATUS_ID } from 'variables/cart';
import { CartType } from 'types';

type Data = {
    insert_team_builder_Order: {
        returning: {
            created_at: string;
            order_id: number;
        }[];
    }
};

const ContactUsModal = () => {
    const { arrNodesTeam, project, contactUsModalVisible, setContactUsModalVisible, contactInfo } = useTeam();
    const [terms, setTerms] = useState(false);
    const { data: dataCart, refetch: refetchCart } = useQuery(GET_CART, {
        variables: {
            project_id: project?.project_id
        }
    });
    const navigate = useNavigate();
    const { refetch: refetchLastOrder } = useQuery(GET_LAST_ORDER);

    const cart: CartType[] = dataCart?.team_builder_Cart_Order_NEW

    const [insertToCart] = useMutation(INSERT_TO_CART);
    const [updateStatusCart] = useMutation(UPDATE_STATUS_CART);
    const [createtOrderNumber] = useMutation(CREATE_ORDER_NUMBER, {
        variables: {
            order_obj: {
                email_to_contact: contactInfo?.email_to_contact,
                phone_to_contact: contactInfo?.phone_to_contact,
                comment: contactInfo?.phone_to_contact,
            }
        }
    });

    const makeOrder = async () => {
        if (cart.length > 0) {
            const orderNumber = await createtOrderNumber().then(({ data }: FetchResult<Data>) => data && data.insert_team_builder_Order.returning[0].order_id)
            await Promise.all(cart.map((c) => updateStatusCart({
                variables: {
                    cart_id: c.cart_id,
                    status: CART_STATUS_ID.ORDER,
                    order_number: orderNumber
                }
            }))).then(() => navigate(`/order/${orderNumber}`)).finally(() => {
                refetchCart()
                refetchLastOrder()
            })
        } else if (project) {
            const orderNumber = await createtOrderNumber().then(({ data }: FetchResult<Data>) => data && data.insert_team_builder_Order.returning[0].order_id)
            await Promise.all(arrNodesTeam.map((node) => insertToCart({
                variables: {
                    proj_obj: {
                        project_hierarchy_id: node.id || '',
                        status_id: CART_STATUS_ID.ORDER,
                        employee_id: null,
                        order_id: orderNumber
                    }
                }
            }))).then(() => navigate(`/order/${orderNumber}`)).finally(() => {
                refetchCart()
                refetchLastOrder()
            })
        }
        setContactUsModalVisible(false);
    }

    return (
        <Modal 
        className='contactUsModal'
        width={'auto'} 
        closeIcon={<Close />} 
        footer={null} 
        centered 
        visible={contactUsModalVisible} onCancel={() => { setContactUsModalVisible(false) }}>
            <div className="contactUsModal-header">
                <h1>CONTACT US</h1>
                <span>A manager will select a team according to your project information</span>
            </div>
            <Row gutter={20}>
                <Col span={12}>
                    <AboutProject />
                    <ContactInfo terms={terms} setTerms={setTerms} />
                </Col>
                <Col span={12}>
                    <TeamOrder cart={cart} />
                    {cart?.length > 0 && <OrderTotalAmount cart={cart} />}
                </Col>
            </Row>
            <footer className="footer-project">
                <div>
                    <button onClick={() => setContactUsModalVisible(false)} className="button-white button-small">Close</button>
                    <button type='submit' onClick={makeOrder} className="ant-btn ant-btn-default button-orange button-small" disabled={!terms}>Contact us</button>
                </div>
            </footer>
            <AgreementModal />
        </Modal>
    )

};

export default ContactUsModal;
