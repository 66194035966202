import { FC, MouseEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSignInEmailPassword, useSignUpEmailPassword, useUserData } from '@nhost/react';

import { useTeam } from 'context/TeamContext';

import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_COMPANY, INSERT_COMPANY_USER } from 'gql/companyUser';
import { INSERT_COMPANY } from 'gql/company';

import { Input, Button, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EyeInvisibleOutlined, EyeTwoTone, SendOutlined, CheckOutlined } from '@ant-design/icons';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
// import PhoneInput from 'react-phone-input-2';

import { AgreementModal } from 'components/AgreementModal';
import { checkMail } from 'variables/regExpEmail';

import 'react-phone-input-2/lib/style.css';
import './SingUpModal.scss'

interface IProps {
   wrapModal: MouseEventHandler<HTMLButtonElement>;
   isVerificationEmailSuccess: boolean;
}

interface ISignUp {
   company: string;
   name: string;
   surname: string;
   email: string;
   password: string;
}

const SignUp: FC<IProps> = ({ wrapModal, isVerificationEmailSuccess }) => {
   const { setModalVisible, setAgreementModalVisible, setCompany } = useTeam();

   const defaultValues: ISignUp = {
      company: '',
      name: '',
      surname: '',
      email: '',
      password: '',
   };

   const authForm = useForm<ISignUp>({
      mode: 'onSubmit',
      defaultValues,
   });

   const { watch, handleSubmit, control, formState: { errors } } = authForm;

   const [agreement, setAgreement] = useState(false);
   const [emailError, setEmailError] = useState('');
   const [disableEmailInput, setDisableEmailInput] = useState<boolean>(true);
   const [verificationError, setVerificationError] = useState('');
   const [needsEmailVerification, setNeedsEmailVerification] = useState(false);
   const [numberOfRegistration, setNumberOfRegistration] = useState(
      Number(localStorage.getItem('numberOfRegistration')) || 0,
   );
   const [verificationEmailSuccess, setIsVerificationEmailSuccess] = useState(false);
   const [verificationCodeSended, setVerificationCodeSended] = useState(false);
   const [disabled, setDisabled] = useState(true);

   const [paused, setPaused] = useState(true);
   const [over, setOver] = useState(false);
   const [[m, s], setTime] = useState([3, 0]);

   const navigate = useNavigate();
   const userData = useUserData();
   const { signUpEmailPassword, error } = useSignUpEmailPassword();
   const { signInEmailPassword } = useSignInEmailPassword();

   const [insertCompany] = useMutation(INSERT_COMPANY);
   const [insertUserCompany] = useMutation(INSERT_COMPANY_USER);
   const { refetch } = useQuery(GET_USER_COMPANY);

   const onSubmit: SubmitHandler<ISignUp> = async ({ email, password, name, surname, company }) => {
      const message = error?.message || '';
      setEmailError(message);
      try {
         setTime([3, 0]);
         setPaused(false);
         setOver(false);
         setPaused(false);
         setDisabled(true);
         const response = await signUpEmailPassword(email, password, {
            displayName: `${name} ${surname}`.trim(),
            metadata: {
               firstName: name,
               lastName: surname,
               // phoneNumber,
               companyName: company,
            },
         });
         if (response.error?.status === 409) {
            setVerificationCodeSended(false);
            setEmailError('Email already in use. Please sign in to your account');
         } else if (response.error?.status === 400) {
            setVerificationCodeSended(false);
            setEmailError(response.error.message)
         } else {
            setVerificationCodeSended(true);
         }
      } catch (e) {
         console.log(e);
      }

      // setNeedsEmailVerification(true);
      if (numberOfRegistration < 5) {
         setNumberOfRegistration(numberOfRegistration + 1);
      }
   };

   const tick = () => {
      if (paused || over) return;

      if (m === 0 && s === 0) {
         setOver(true);
      } else if (s === 0) {
         setTime([m - 1, 59]);
      } else {
         setTime([m, s - 1]);
      }
   };

   // const errorPassword = () => {
   //    let errorMessage = '';
   //    if (!validatePassword(password) && passwordConfirmation !== '') {
   //       errorMessage = `Password must contain from 8 to 14 characters, 
   //              include at least 1 capital letter, 
   //              include at least 1 digit`;
   //    }
   //    if (password !== passwordConfirmation && passwordConfirmation !== '') {
   //       errorMessage = 'Password is not equal to previous one';
   //    }
   //    return errorMessage;
   // };

   const insertCompanyHandler = async (company_nm: string) => {
      try {
         return await insertCompany({
            variables: {
               company: {
                  company_nm,
               },
            },
         });
      } catch (e) {
         console.log(e);
      }
   };

   const insertCompanyUserHandler = async (company_id: string, user_id: string) => {
      try {
         await insertUserCompany({
            variables: {
               companyUser: {
                  company_id,
                  user_id,
               },
            },
         });
      } catch (e) {
         console.log(e);
      }
   };

   const handleChangeVerificationEmail = (e: any) => {
      if (e.target.value.length === 6) {
         fetch(
            `${process.env.REACT_APP_NHOST_BACKEND_URL}/v1/auth/verify?&ticket=${
               e.target.value
            }&type=emailVerify&redirectTo=${process.env.REACT_APP_NHOST_BACKEND_URL?.replace(':1337', '')}`,
         )
            .then((response) => {
               if (response.status === 401) {
                  setVerificationError('Invalid code');
               } else {
                  setNumberOfRegistration(0);
                  setVerificationError('');
                  setIsVerificationEmailSuccess(true);
               }
            })
            .catch((e) => console.log(e));
      }
   };

   const handleCreateAccount: SubmitHandler<ISignUp> = async ({email, password, company}) => {
         await signInEmailPassword(email, password).then((res) => res.isSuccess && setModalVisible(false));
         
         await insertCompanyHandler(company);
   };

   const changeAgreement = (checked: boolean) => {
      setAgreement(checked);
   };

   useEffect(() => {
      if(!emailError) {
         const timerID = setInterval(() => tick(), 1000);
         return () => clearInterval(timerID);
      }
   });

   useEffect(() => {
      const message = error?.message || '';
      setEmailError(message);
   }, [error]);

   useEffect(() => {
      if (userData && verificationEmailSuccess) {
         (async () => {
            const {
               id,
               metadata: { companyName },
            } = userData;

            const newCompany: any = await insertCompanyHandler(companyName as string);

            await insertCompanyUserHandler(newCompany.data.insert_team_builder_Company_one.company_id, id);

            setCompany({
               company_id: newCompany.data.insert_team_builder_Company_one.company_id,
               company_nm: companyName as string,
            })

            await refetch({ user_id: id });
            setNumberOfRegistration(0);
         })();
      }
   }, [userData, verificationEmailSuccess]);

   useEffect(() => {
      setNeedsEmailVerification(false);
   }, []);

   useEffect(() => {
      localStorage.setItem('numberOfRegistration', String(numberOfRegistration));
   }, [numberOfRegistration]);

   useEffect(() => {
      const subscription = watch(({ company, name, surname, password, email }) => {

         if(company && name && surname && password) {
            setDisableEmailInput(false);
         } else {
            setDisableEmailInput(true);
         }

         if(company && name && surname && password && email) {
            setDisabled(false);
         } else {
            setDisabled(true);
         }
      }
      )
      return () => subscription.unsubscribe()
    }, [watch])

   if (needsEmailVerification) {
      return (
         <div className='checkemail'>
            <p>
               To finish registration process, kindly verify your email address. We have sent you an email with the
               link. Kindly follow it to finish the registration.
            </p>
            <button onClick={wrapModal} type='button' className='button-white'>
               Sign In
            </button>
         </div>
      );
   }

   if (isVerificationEmailSuccess) {
      return (
         <div className='verificationEmailSuccess'>
            <p>You email is verified. Welcome to Team Builder!</p>
            <div className='buttons'>
               <button
                  type='submit'
                  className='button-orange'
                  onClick={() => {
                     setModalVisible(false);
                     navigate('/landing');
                  }}
               >
                  Close
               </button>
            </div>
         </div>
      );
   }

   // if (numberOfRegistration === 5) {
   //    return (
   //       <div className='verificationEmailSuccess'>
   //          <p>You have signed up for 5 times. Verify one of the registrations, please</p>
   //          <div className='buttons'>
   //             <button type='submit' className='button-orange' onClick={wrapModal}>
   //                Sign In
   //             </button>
   //          </div>
   //       </div>
   //    );
   // }

   return (
      <>
         <form onSubmit={handleSubmit(handleCreateAccount)}>
            <div className='info-form'>
               <div>
                  <InfoIcon className='info-icon active' />
               </div>
               <div className='info-text'>
                  To get an access to the skills, roles and team builder functionality kindly register. It will take
                  just 1 minute. Fields marked with '*' are mandatory.
               </div>
            </div>
            <div className='sign-up-form'>
               <div>
                  <label>
                     Company <span className='required'>*</span>
                  </label>
               </div>
               <div className='input-text'>
                  <Controller
                     name='company'
                     control={control}
                     rules={{ required: 'The company is not specified' }}
                     render={({ field }) => (
                        <Input 
                        {...field}
                        />
                     )}
                  />
                  {errors?.company && <div className='error-message'>{errors.company.message}</div>}
               </div>
               <div>
                  <label>
                     Name <span className='required'>*</span>
                  </label>
               </div>
               <div className='input-text'>
                  <Controller
                     name='name'
                     control={control}
                     rules={{ required: 'The first name is not specified' }}
                     render={({ field }) => (
                        <Input
                        {...field}
                        />
                     )}
                  />
                  {errors?.name && <div className='error-message'>{errors.name.message}</div>}
               </div>
               <div>
                  <label>
                     Surname <span className='required'>*</span>
                  </label>
               </div>
               <div className='input-text'>
                  <Controller
                     name='surname'
                     control={control}
                     rules={{ required: 'The surname is not specified' }}
                     render={({ field }) => (
                        <Input
                        {...field}
                        />
                     )}
                  />
                  {errors?.surname && <div className='error-message'>{errors.surname.message}</div>}
               </div>
               <div>
                  <label>
                     Password <span className='required'>*</span>
                  </label>
               </div>
               <div className='input-text'>
                  <Controller
                     name='password'
                     control={control}
                     rules={{ required: 'The password is not specified' }}
                     render={({ field }) => (
                        <Input.Password
                           {...field}
                           autoComplete='off'
                           iconRender={(visible) => (visible ? <EyeTwoTone aria-invalid /> : <EyeInvisibleOutlined aria-invalid />)}
                        />
                     )}
                  />
                  {errors?.password && <div className='error-message'>{errors.password.message}</div>}
               </div>
               {/* <div>
                  <label>
                     Phone <span className='required'>*</span>
                  </label>
               </div>
               <div className='input-text'>
                  <Controller
                     name='phone'
                     control={control}
                     rules={{ required: 'The phone is not specified' }}
                     render={({ field }) => (
                        <PhoneInput
                           {...field}
                           inputProps={{ name: 'phone' }}
                           country={'us'}
                           value={phoneNumber}
                           onChange={(phone) => {
                              setValuePhoneNumber(phone);
                              field.onChange(phone);
                           }}
                           inputClass='ant-input'
                           containerClass='ant-input-affix-wrapper'
                           inputStyle={{ paddingLeft: 48, height: 30 }}
                           buttonStyle={{ backgroundColor: 'unset', border: 'none' }}
                           containerStyle={{ borderRadius: 4, border: '1px solid #DDE3E8' }}
                        />
                     )}
                  />
                  {errors?.phone && <div className='error-message'>{errors?.phone?.message}</div>}
               </div> */}
               {!verificationCodeSended ? (
                  <>
                     <div></div>
                     <div className='verification-email'>
                        <span>It is necessary to pass verification</span>
                     </div>
                  </>
               ) : (
                  <>
                     <div></div>
                     <div></div>
                  </>
               )}
               <div className='verification-email-label'>
                  <label>
                     E-mail <span className='required'>*</span>
                  </label>
               </div>
               <div className='input-text'>
                  <Controller
                     name='email'
                     control={control}
                     rules={{ 
                        required: 'The email is not specified', 
                        pattern: {
                           value: checkMail,
                           message: 'Email is incorrectly formatted', 
                        }
                     }}
                     render={({ field }) => (
                        <div className='email-verification-wrapper'>
                           <Input
                              {...field}
                              disabled={disableEmailInput}
                              onInput={(e: any) => {
                                 setEmailError('');
                              }}
                           />
                           {!verificationEmailSuccess ? (
                              <Button
                                 disabled={disabled}
                                 type='link'
                                 icon={<SendOutlined aria-invalid />}
                                 onClick={handleSubmit(onSubmit)}
                              >
                                 Send code Email
                              </Button>
                           ) : (
                              <div className='email-verified-block'>
                                 <CheckOutlined aria-invalid /> Verified
                              </div>
                           )}
                        </div>
                     )}
                  />
                  {(errors?.email || error) && (
                     <div className='error-message'>
                           {errors?.email?.message || emailError}
                     </div>
                  )}
               </div>
               <div></div>
               {verificationCodeSended && !verificationEmailSuccess && !emailError && (
                  <>
                     <div>
                        <div className='label-for-email-code'>
                           <label>Confirm the email with code
                           </label>
                        </div>
                        <Input onChange={handleChangeVerificationEmail} />
                        {verificationError && (
                           <>
                              <div className='error-message'>{verificationError}</div>
                              <div></div>
                           </>
                        )}
                        <div className='timer'>
                           Сode is still valid{' '}
                           <span>{`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}</span>
                        </div>
                     </div>
                     <div></div>
                  </>
               )}
               <div className='agreement'>
                  <div className='agrees'>
                     <div>
                        <Checkbox
                           onChange={(event: CheckboxChangeEvent) => {
                              changeAgreement(event.target.checked)
                              }
                           }
                           checked={agreement}
                        />
                     </div>
                     <p className='agree'>
                        I agree to the{' '}
                        <span onClick={() => setAgreementModalVisible(true)}>
                           Privacy Statement
                        </span>
                     </p>
                  </div>
               </div>
               <div></div>
            </div>
         </form>
         <div className='buttons-wrapper'>
            <div className='buttons'>
               <button onClick={wrapModal} type='button' className='button-white'>
                  Sign In
               </button>
               <button
                  disabled={!verificationEmailSuccess || !agreement}
                  type='submit'
                  className='button-orange'
                  onClick={handleSubmit(handleCreateAccount)}
               >
                  Create account
               </button>
            </div>
         </div>
         <AgreementModal />
      </>
   );
};

export default SignUp;
