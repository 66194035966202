import { FC, useEffect, useState } from 'react';
import { useChangePassword } from '@nhost/react';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'antd';
import { useTeam } from 'context/TeamContext';
import { validatePassword } from 'utils/validate';
import { useNavigate } from 'react-router-dom';

interface IChangePasswordField {
   password: string;
   confirmPassword: string;
}

const ChangePassword: FC = () => {
   const [password, setPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [passwordError, setPasswordError] = useState('');

   const navigate = useNavigate();
   const { setChangePasswordFormVisible, setModalVisible } = useTeam();
   const { changePassword, isSuccess } = useChangePassword();
   const { handleSubmit, control, formState: { errors } } = useForm<IChangePasswordField>();

   const isPasswordFormValid = password !== '' && password === confirmPassword;

   const onSubmit = async () => {
      const { isError, isSuccess, error } = await changePassword(password);

      if (isError) {
         console.log(error);
         setPasswordError('Произошла ошибка, попробуйте еще раз.');
      } else if (isSuccess) {
         setPassword('');
         setConfirmPassword('');
      }
   };

   const errorPassword = () => {
      let errorMessage = '';
      if (!validatePassword(password) && confirmPassword !== '') {
         errorMessage = `Password must contain from 8 to 14 characters, 
              include at least 1 capital letter, 
              include at least 1 digit`;
      }
      if (password !== confirmPassword && confirmPassword !== '') {
         errorMessage = 'Password is not equal to previous one';
      }
      return errorMessage;
   }

   useEffect(() => {
      const message = errorPassword();
      setPasswordError(message);
   }, [confirmPassword, password])

   return (
      <>
         {isSuccess ? (
            <div>
               <p>Your password has been successfully changed</p>
               <div className="buttons">
                  <button
                     onClick={() => {
                        setModalVisible(false);
                        setChangePasswordFormVisible(false);
                        navigate('/landing');
                     }}
                     type="button"
                     className="button-white"
                  >
                     Welcome back
                  </button>
               </div>
            </div>
         ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
               <div>
                  <div>
                     <label>Password</label>
                  </div>
                  <div className="input-text">
                     <Controller
                        name="password"
                        control={control}
                        rules={{ required: 'The password is not specified' }}
                        render={({ field }) => (
                           <Input.Password
                              {...field}
                              onInput={(e: any) => {
                                 setPassword(e.target.value);
                              }}
                           />
                        )}
                     />
                     {errors?.password && (
                        <div>
                           {errors?.password?.message}
                        </div>
                     )}
                  </div>
                  <div>
                     <label>Password Confirm</label>
                  </div>
                  <div className="input-text">
                     <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{ required: 'The password is not specified' }}
                        render={({ field }) => (
                           <Input.Password
                              {...field}
                              onInput={(e: any) => {
                                 setConfirmPassword(e.target.value);
                              }}
                           />
                        )}
                     />
                     {(errors?.confirmPassword || errorPassword()) && 
                     <div>
                        {errors?.confirmPassword?.message || passwordError}
                     </div>}
                  </div>
                  <div></div>
                  <div className="buttons">
                     <button
                        type="submit"
                        className="button-orange"
                        disabled={!isPasswordFormValid}
                     >
                        Change password
                     </button>
                  </div>
               </div>
            </form>
         )}
      </>
   );
};

export default ChangePassword;
