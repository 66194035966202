import { FC, MouseEventHandler, useEffect, useState } from 'react';
import { useSignInEmailPassword } from '@nhost/react';
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Input } from "antd";
import { useTeam } from 'context/TeamContext';

import './SingInModal.scss'

interface IProps {
    wrapModal: MouseEventHandler<HTMLButtonElement>
}

interface ISignInField {
    email: string,
    password: string
}

const SignIn: FC<IProps> = ({ wrapModal }) => {
    const { setModalVisible, modalVisible, setResetPasswordFormVisible } = useTeam();
    const { handleSubmit, control, formState: { errors }, reset } = useForm<ISignInField>();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');

    const { signInEmailPassword, isLoading, error, needsEmailVerification } = useSignInEmailPassword();

    const onSubmit: SubmitHandler<ISignInField> = (e: any) => {
        signInEmailPassword(email, password).then(res => res.isSuccess && setModalVisible(false));
    }

    useEffect(() => {
        const message = error?.message || '';
        setEmailError(message);
    }, [error])

    useEffect(() => {
        if (!modalVisible) {
            setEmail('')
            setEmailError('')
            setPassword('')
            reset()
        }
    }, [modalVisible, reset])

    const disableForm = isLoading;

    const errorEmail = () => {
        const status = ((error && error?.status) || 0) || 0;
        if ([409, 400, 401, 10].includes(status)) {
            return error?.message;
        }
        return '';
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="sign-up-form">
                <div>
                    <label>E-mail</label>
                </div>
                <div className="input-text">
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: 'The email is not specified' }}
                        render={({ field }) => <Input {...field} onInput={(e: any) => {
                            setEmail(e.target.value);
                        }} />}
                    />
                    {(errors?.email || errorEmail()) && <div className="error-message">{errors?.email?.message || emailError}</div>}
                    {needsEmailVerification && <div className="error-message">Verify your email to continue working in system</div>}
                </div>
                <div>
                    <label>Password</label>
                </div>
                <div className="input-text">
                    <Controller
                        name="password"
                        control={control}
                        rules={{ required: 'The password is not specified' }}
                        render={({ field }) => <Input.Password
                            {...field}
                            onInput={(e: any) => setPassword(e.target.value)}
                            iconRender={visible => (visible ? <EyeTwoTone aria-invalid /> : <EyeInvisibleOutlined aria-invalid />)}
                        />}
                    />
                    {errors?.password && <div className="error-message">{errors?.password?.message}</div>}
                </div>
                <div>
                </div>
                <div className="agrees">
                    <p className="registration">{/*Registration*/}</p>
                    <p className="agree">
                        <span onClick={() => setResetPasswordFormVisible(true)}>Forgot password?</span>
                    </p>
                </div>
                <div>

                </div>
                <div className="buttons">
                    <button onClick={wrapModal} type="button" className="button-white">Sign Up</button>
                    <button type="submit" disabled={disableForm} className="button-orange">
                        Continue
                    </button>
                </div>
            </div>
        </form>
    )
}

export default SignIn;
