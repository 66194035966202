import gql from "graphql-tag";

export const INSERT_COMPANY_USER = gql`
  mutation insertUserCompany($companyUser: team_builder_User_Company_insert_input!) {
  insert_team_builder_User_Company_one(object: $companyUser) {
    id
  }
}
`;



export const GET_USER_COMPANY = gql`
  query getUserCompany($user_id: uuid!) {
    team_builder_User_Company(where: {
    user_id: {
      _eq: $user_id
    }
  }) {
    user_id,
    Company{
      company_nm,
      company_id
    }
  }
  }
`;
