import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useResetPassword } from '@nhost/react';
import { Input } from 'antd';
import { useTeam } from 'context/TeamContext';

import './ResetPasswordModal.scss'
interface IResetPasswordField {
   email: string;
}

const ResetPassword: FC = () => {
   const [email, setEmail] = useState('');
   const [emailError, setEmailError] = useState('');

   const { setResetPasswordFormVisible } = useTeam();
   const {
      handleSubmit,
      control,
      formState: { errors },
   } = useForm<IResetPasswordField>();

   const { resetPassword, isLoading, isSent, error } = useResetPassword();

   const onSubmit = () => {
      resetPassword(email, {
         redirectTo: `${process.env.REACT_APP_NHOST_BACKEND_URL?.replace(':1337', '')}/landing?resetpassword`,
      });
   };
   
   useEffect(() => {
      const message = error?.message || '';
      setEmailError(message);
  }, [error])

   const errorEmail = () => {
      const status = (error && error?.status) || 0 || 0;
      if ([409, 400, 401, 10].includes(status)) {
         return error?.message;
      }
      return '';
   };

   return (
      <>
         {isSent ? (
            <>
               <div className='sentText-block'>
                  <p>
                     An email has been sent to <b>{email}</b>. Please follow the link in the email to reset your
                     password.
                  </p>
                  <p>
                     Already have an account? <span onClick={() => setResetPasswordFormVisible(false)}>Sign in</span>
                  </p>
               </div>
            </>
         ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
               <div className='password-reset-form'>
                  <div>
                     <label>E-mail</label>
                  </div>
                  <div>
                     <Controller
                        name='email'
                        control={control}
                        rules={{ required: 'The email is not specified' }}
                        render={({ field }) => (
                           <Input
                              {...field}
                              onInput={(e: any) => {
                                 setEmail(e.target.value);
                              }}
                           />
                        )}
                     />
                     {(errors?.email || errorEmail()) && <div className='error-message'>{errors?.email?.message || emailError}</div>}
                  </div>
                  <div></div>
                  <div className='buttons'>
                     <button onClick={() => setResetPasswordFormVisible(false)} type='button' className='button-white'>
                        Sign In
                     </button>
                     <button type='submit' className='button-orange'>
                        {isLoading ? 'Loading...' : 'Send Reset Link'}
                     </button>
                  </div>
               </div>
            </form>
         )}
      </>
   );
};

export default ResetPassword;
