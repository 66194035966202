import { RoleType } from "types";

type ArrNodes = {
    cart: any[];
    count: number;
    id: string;
    name: string;
    parent?: string;
    parent_role_id: string | null;
    role_id: string;
    size: number;
};

function getArrChildNodes(arrNodes: ArrNodes[], node?: ArrNodes) {
    return arrNodes.filter((item) => item.parent_role_id === node?.role_id).map((item) => item.role_id);
};

export const getArrNodes = (projectHierarchy: RoleType[]) => {
    
    const result: ArrNodes[] = [];
    let arrNodes: ArrNodes[] = [];
    if (projectHierarchy.length > 0) {
        arrNodes = projectHierarchy.map((item) => ({
            id: item.id,
            role_id: item.role_id,
            parent_role_id: item.parent_role_id,
            parent: projectHierarchy.find((el) => el.role_id === item.parent_role_id)?.id,
            name: item.Role_dict.role_nm,
            size: 1,
            count: item.required_number,
            cart: item.Cart_Order_NEWs
        }))
    }

    const parentNodeNull = arrNodes.filter((item) => !item.parent_role_id);
    parentNodeNull?.forEach((parent) => {
        const childNodes = getArrChildNodes(arrNodes, parent);

        const addNodes = (id: string, arr: ArrNodes[]) => {
            const node = arr.find((item) => item.role_id === id);
            
            node && result.push({ ...node });
            const childNodesNode = getArrChildNodes(arr, node);
            if (childNodesNode.length > 0) {
                childNodesNode.forEach((itemone) => {
                    addNodes(itemone, arr);
                });
            }
        };

        if (childNodes.length > 0) {
            addNodes(parent.role_id, arrNodes);
        } else {
            result.push({ ...parent })
        }
    })
    return result;
};