export const PrivacyPolicyText = () => {
   return (
      <>
         <h2>Privacy Policy</h2>
         <p>
            This Privacy Policy was last modified on June 20, 2023.
            <br /> Enfint SA (PTY) ("us", "we", or "our") operates https://teams.enfint.tech/ (hereafter referred to as
            "the Site"). This page informs you about our policies regarding the collection, use, and disclosure of
            personal data from users of the Site.
            <br />
            <br /> Our privacy policy is based on the General Data Protection Regulation (GDPR) of the European Union
            and the Protection of Personal Information Act (POPI) of South Africa. Furthermore, we comply with
            applicable U.S. data privacy laws. We highly value your privacy and have implemented stringent measures to
            safeguard your data. The data collected serves various purposes, such as service enhancement, communication
            with visitors, processing user data for sales, and providing services in line with the Site's
            specialization.
            <br />
            <br />
            <b>Data Collection and Processing</b>
            <br />
            We gather and process your personal data only with your explicit consent. The data may include your name,
            surname, e-mail address, phone number and user uploaded content. Data gathering process is conducted in
            accordance with the laws of the European Union, the United States and South Africa’s Protection of Personal
            Information Act (POPI).
            <br />
            <br />
            <b>User Uploaded Content</b>
            <br />
            Our platform allows users to upload various types of content, including but not limited to, Word and PDF
            documents, images, and descriptions of their systems or ideas (collectively referred to as "User Uploaded
            Content"). This User Uploaded Content may contain personally identifiable information or confidential
            business information. Enfint SA (PTY) does not claim any ownership rights in your User Uploaded Content. You
            retain all ownership rights in your User Uploaded Content, but you grant us a license to use, store, copy,
            and display your User Uploaded Content to the extent necessary to provide, improve, promote and protect our
            Services. Please remember that you are responsible for your User Uploaded Content. We encourage you to
            anonymize or encrypt sensitive information and to maintain copies of your content. Enfint SA (PTY) will
            implement reasonable safeguards to protect your User Uploaded Content, but no security measures are
            completely secure, and we cannot guarantee the security of your User Uploaded Content. If you wish to remove
            your User Uploaded Content, please contact us at teamsenfint@gmail.com. By uploading content to our
            platform, you represent and warrant that you have all necessary rights to upload such content and grant us
            the necessary licenses, and that your content does not violate our Acceptable Use Policy, any applicable
            laws, or any third-party rights.
            <br />
            <br />
            <b>Data Storage, Alteration, and Removal</b>
            <br />
            The user providing their personal data to https://teams.enfint.tech/ maintains the ability to manage his
            personal data in various ways:
            <br />
            <u>For Data Alteration or Removal</u>: Users who discover inaccuracies in their personal data can reach out
            to us at teamsenfint@gmail.com for necessary corrections or updates. Similarly, if a user prefers to have
            some or all of their personal data removed from our systems, they can submit a request to the same email
            address.
            <br />
            <u>Limitation and Objection to Data Usage</u>: If the user finds that his personal data is inaccurately
            recorded or unlawfully maintained, he may request that we cease using some or all of his data or limit its
            usage.
            <br />
            <u>Data Access and Transfer</u>: At any given time, the user can request a complete copy of his data. This
            data, once received, can be transferred to another data controller of his choice. We will provide the data
            within 10 working days in an MS Excel format spreadsheet. For processing any of these data requests, please
            contact us at <a href='mailto:teamsenfint@gmail.com'>teamsenfint@gmail.com</a>.<br />
            <br />
            <b>Log Files and Technical Data</b>
            <br />
            Our system collects data from your visits such as IP address, time of visit, browser settings, and operating
            system. This data is essential for the correct display of the website’s content. Additionally, we utilize
            log files following a standard procedure for better trend analysis, site administration, and user movement
            tracking on the website.
            <br />
            <br />
            <b>Third-Party Services</b>
            <br />
            Our Site uses third-party services such as Google Analytics, which collect data independently. These
            services may share data with their organization's services and utilize the data for advertising
            personalization. Users can find the user agreements of these organizations on their respective websites and
            can opt-out of their data collection.
            <br />
            <br />
            <b>Links to Other Websites</b>
            <br />
            Our Site may contain links to other websites outside our control. We cannot be held responsible for the
            content or privacy policies of these sites. Users are advised to familiarize themselves with the privacy
            policies of these sites.
            <br />
            <br />
            <b>Changes to the Privacy Policy</b>
            <br />
            We may update our privacy policy from time to time. Changes will be communicated to users by posting the
            updated policy on this webpage. We are committed to staying updated on legislation related to personal data
            in the European Union, South Africa and United States. If your personal data is stored with us, we will
            notify you about any changes to our privacy policy. If your personal data, and more specifically, your
            contact information was entered incorrectly, we will not be able to contact you.
            <br />
            <br />
            <b>Feedback and Final Clauses</b>
            <br />
            For any queries related to our privacy policy, contact our administration at{' '}
            <a href='mailto:teamsenfint@gmail.com'>teamsenfint@gmail.com</a>. Users who do not agree with this privacy
            policy should refrain from using our services and visiting our website.
            <br />
            <br />
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
         </p>
      </>
   );
};
