import { useNavigate } from 'react-router-dom';

import './PrivacyStatement.scss';
import { PrivacyPolicyText } from 'components/PrivacyPolicyText';

export const PrivacyStatement = () => {
   const navigate = useNavigate();

   return (
      <div className='privacy-wrapper'>
         <header className='privacy-header'>
            <h1>Privacy Statement</h1>
         </header>
         <div className='privacy-main'>
            <PrivacyPolicyText />
         </div>
         <footer className='privacy-footer'>
            <button onClick={() => navigate(-1)} className='button-white secondary big'>
               Return to main page
            </button>
         </footer>
      </div>
   );
};
