import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTeam } from 'context/TeamContext';
import { Checkbox, Input } from 'antd';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { AgreementModal } from 'components/AgreementModal';
import { checkMail } from 'variables/regExpEmail';

import './ContactInfo.scss';

const { TextArea } = Input;

interface IProps {
   view?: boolean;
   full?: boolean;
   terms?: boolean;
   setTerms?: (terms: boolean) => void;
}

const ContactInfo = ({ view, full, terms, setTerms }: IProps) => {
   const { pathname } = useLocation();
 
   const { user, contactInfo, setUser, setContactInfo, setAgreementModalVisible } = useTeam();

   const [name, setName] = useState('');
   const [surname, setSurname] = useState('');
   const [company, setCompany] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [phoneCountry, setPhoneCountry] = useState('')
   const [comment, setComment] = useState('');
   const [emailError, setEmailError] = useState(false);

   const setValuePhoneNumber = (value: string) => {
      setPhone(value);
      const metadata = {...user?.metadata, phoneNumber: value}
      setUser({...user, metadata})
   };

   const isValidEmailAddress = (email: string) => {
      if (!checkMail.test(email)) {
         setEmailError(true);
      } else {
         setEmailError(false);
      }
   };

   useEffect(() => {
      setEmail(user?.email ?? '');
      setPhone(user?.metadata.phoneNumber ?? '');
      setName(user?.metadata.firstName ?? '');
      setSurname(user?.metadata.lastName ?? '');
      setCompany(user?.metadata.companyName ?? '');
   }, [user]);

   useEffect(() => {
      setContactInfo({
         email_to_contact: email,
         phone_to_contact: phone,
         comment,
         surname,
         name,
         company,
      });
   }, [email, phone, comment, company, setContactInfo, surname, name]);

   useEffect(() => {
      if(contactInfo.comment) {
         setComment(contactInfo.comment);
      }
   },[]);

   const handleInputPhoneChange = (phone: string, country: CountryData | {}) => {
      if ("countryCode" in country && phoneCountry !== country?.countryCode) {
         setValuePhoneNumber(`+${country.dialCode}`)
         setPhoneCountry(country.countryCode)
      } else {
         setValuePhoneNumber(phone);
      }
   }

   return (
      <>
         <div className='contactInfo'>
            <div className='contactInfo-header'>
               <h2>Contact information</h2>
            </div>
            <div className='contactInfo-content'>
               {full && (
                  <>
                     <div className='title'>
                        <span className='grey'>
                           Name:<span className='required'>*</span>
                        </span>
                        {view ? (
                           <span>{name}</span>
                        ) : (
                           <Input value={name} onChange={(e) => setName(e.target.value)} size='large' required />
                        )}
                     </div>
                     <div className='title'>
                        <span className='grey'>
                           Surname:<span className='required'>*</span>
                        </span>
                        {view ? (
                           <span>{surname}</span>
                        ) : (
                           <Input value={surname} onChange={(e) => setSurname(e.target.value)} size='large' required />
                        )}
                     </div>
                     <div className='title'>
                        <span className='grey'>
                           Company:<span className='required'>*</span>
                        </span>
                        {view ? (
                           <span>{company}</span>
                        ) : (
                           <Input value={company} onChange={(e) => setCompany(e.target.value)} size='large' required />
                        )}
                     </div>
                  </>
               )}
               <div className='title'>
                  <span className='grey'>
                     Email:<span className='required'>*</span>
                  </span>
                  {view ? (
                     <span>{email}</span>
                  ) : (
                     <>
                        <Input
                           type='email'
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           onBlur={() => isValidEmailAddress(email)}
                           size='large'
                           disabled={pathname === '/order'}
                           required
                        />
                        {emailError && <div className='error-message'>The email is not specified</div>}
                     </>
                  )}
               </div>
               {pathname === '/order' && <div className="title">
                  <span className="grey">
                     Phone:
                  </span>
                     {view ? (
                        <span>{phone}</span>
                     ) : (
                        <PhoneInput
                           inputProps={{ name: 'phone' }}
                           country={'us'}
                           value={phone}
                           onChange={handleInputPhoneChange}
                           inputClass='ant-input'
                           containerClass='ant-input-affix-wrapper'
                           inputStyle={{ paddingLeft: 48, height: 30 }}
                           buttonStyle={{ backgroundColor: 'unset', border: 'none' }}
                           containerStyle={{ borderRadius: 4, border: '1px solid #DDE3E8' }}
                        />
                     )}
               </div>}
               <div className='title'>
                  <span className='grey'>Comment:</span>
                  {view ? (
                     <span>{comment}</span>
                  ) : (
                     <TextArea
                        rows={3}
                        placeholder='Enter text'
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                     />
                  )}
               </div>
               {!view && (
                  <div className='term'>
                     <Checkbox checked={terms} onChange={() => setTerms && !emailError && setTerms!(!terms)} 
                     />
                     <p className='agree'>
                        I agree to the{' '}
                        <span onClick={() => setAgreementModalVisible(true)}>
                           Privacy Statement
                        </span>
                     </p>
                  </div>
               )}
            </div>
         </div>
         <AgreementModal />
      </>
      
   );
};

export default ContactInfo;
