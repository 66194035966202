import { ReactComponent as Vector } from 'assets/icons/loader.svg';

import './SuspenseLoader.scss';

const SuspenseLoader = () => (
    <div className='loader-wrapper'>
        <div className='spinner'>
            <Vector className='loader orange tl' />
            <Vector className='loader green tr' />
            <Vector className='loader green bl' />
            <Vector className='loader light-green br' />
        </div>
    </div>
);

export default SuspenseLoader;
