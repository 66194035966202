import Tag from "components/Tag";
import { useTeam } from "context/TeamContext";

import './AboutProject.scss';

const AboutProject = () => {
    const { project } = useTeam();

    return (
        <div className="aboutProject">
            <div className="aboutProject-header">
                <h2>About project</h2>
            </div>
            <div className="aboutProject-content">
                <div className="title">
                    <span className="grey">Project name:</span>
                    <span>{project?.project_nm}</span>
                </div>
                <div className="title">
                    <span className="grey">Development type:</span>
                    <span>{project?.Hierarchy_Type_dict?.type_nm}</span>
                </div>
                <div className="title">
                    <span className="grey">Business domain:</span>
                    <span>{project?.Business_domain_dict?.domain_nm}</span>
                </div>
                {project?.Project_Technologies && (
                    <div className="title">
                        <span className="grey">Technology stack:</span>
                        <p>
                            {project?.Project_Technologies.map((tech: any) => {
                                return (
                                    <Tag fontColor="#434449" color="#FBFBFC" title={tech.Technology_dict.technology_name} key={tech.Technology_dict.technology_name} />
                                )
                            })}
                        </p>
                    </div>
                )}
                <div className="title">
                    <span className="grey">Description:</span>
                    <p>{project?.description}</p>
                </div>
            </div>
        </div>

    )
};

export default AboutProject;
