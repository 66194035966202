import logo from 'assets/images/logo.svg'
import pageDevelopment from 'assets/images/pageDevelopment.svg';

import './DevelopmentPage.scss';

export const DevelopmentPage = () => {
    return (
        <div className='developmentPage-wrapper'>
            <div className="developmentPage-top">
                <img src={logo} alt="" />
                <img src={pageDevelopment} alt="" />
            </div>
            <div className="developmentPage-bottom">
                <div className='developmentPage-header'>
                    In progress
                </div>
                <div className='developmentPage-footer'>
                    While a mobile version of our service is in development, we encourage you to continue using the fully functional web-version
                    to access our platform.
                </div>
            </div>
        </div>
    )
}