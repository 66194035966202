import gql from "graphql-tag";

export const GET_TECHNOLOGIES_QUERY = gql`
  query GetTechnologies {
    team_builder_Technology_dict (order_by: {technology_name: asc}) {
      technology_id, technology_name
    }
  }
`;

export const GET_PROJECT_TECH_STACK = gql`
  query Project_Tech_Stack ($project_id: uuid!) {
    team_builder_Project (where: {project_id: {_eq: $project_id}}){
      Project_Technologies{
        Technology_dict{
          technology_id
          technology_name
        }
      }
  }
}
`;

export const GET_ROLES_TECHNOLOGIES = gql`
  query getRoleTechnologies ($role_id: uuid!){
  team_builder_Technology_dict(order_by: {technology_name: asc}, where: {Employee_Technologies:{Employee:{Employee_Grade_Roles:{Grade_Role_matrix:{Role_dict:{role_id: {_eq: $role_id}}}}}}}){
    technology_id
    technology_name
  }
}

`;
