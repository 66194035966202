import { gql } from "@apollo/client";

export const INSERT_TO_CART = gql`
mutation Cart ($proj_obj:[team_builder_Cart_Order_NEW_insert_input!]! ) {
  insert_team_builder_Cart_Order_NEW (objects: $proj_obj){
    returning{
    cart_id
    }
  }
}
`
export const UPDATE_STATUS_CART = gql`
mutation Cart ($cart_id: uuid, $status: uuid, $order_number: bigint ) {
  update_team_builder_Cart_Order_NEW (
    where: {cart_id: {_eq: $cart_id}},
    _set: {status_id: $status, order_id: $order_number}){
      returning{
        cart_id
      }
  }
}
`

export const CREATE_ORDER_NUMBER = gql`
mutation Mutation_create_order($order_obj: [team_builder_Order_insert_input!]! ) {
  insert_team_builder_Order(objects: $order_obj) {
    returning {
      order_id
      created_at
    }
  }
}`

export const GET_CART = gql`
query getCartOrderNew($project_id: uuid!) {
  team_builder_Cart_Order_NEW(where: {
    _and: [
      {Order_status_dict:{status_nm: {_eq: "CART"}}},
      {Project_Hierarchy_NEW: {project_id: {_eq: $project_id}} }
    ]}) {
    cart_id
    project_hierarchy_id
    updated_at
    Employee{
      employee_id
      employee_first_nm
      employee_second_nm
      specialization
      Employee_Grade_Roles{
        Grade_Role_matrix{
          Grade_dict{
            grade_nm
          }
          Role_dict{
            role_nm
          }
          price
        }
      }
      File_infos{
        file_id
        file_nm
        file_descript
        file_type
        File_type_dict{
          type_nm
        }
      }
    }
    Project_Hierarchy_NEW{
      id
      Role_dict{
        role_nm
        role_id
      }
      roleDictByParentRoleId{
        role_nm
      }
      required_number
      Project{
        project_nm
        planned_start_dt
        planned_finish_dt
        Project_Technologies{
          Technology_dict{
            technology_name
          }
        }
      }
    }
  }}
`

export const GET_LAST_ORDER = gql`
query lastCartOrder {
  team_builder_Cart_Order_NEW(limit: 1, order_by: {updated_at: desc}, where: {order_id: {_is_null: false}}) {
    cart_id
    order_id
    updated_at
  }}
`