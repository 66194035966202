import { CartType } from 'types';
import './OrderTotalAmount.scss';
import { Tooltip } from 'antd';
import { ReactComponent as Info } from 'assets/icons/info.svg';

interface IProps {
    cart: CartType[]
}

const OrderTotalAmount = ({ cart }: IProps) => {
    const totalPrice = cart.reduce((total, cartItem) => {
        const category = cartItem.Project_Hierarchy_NEW.Role_dict.role_nm
        const currentRole = cartItem.Employee?.Employee_Grade_Roles?.find((item) => item.Grade_Role_matrix.Role_dict.role_nm === category)
        const price = currentRole?.Grade_Role_matrix.price
        return total + Number(price)
    }, 0) * 8

    return (
        totalPrice ? <>
            <div className="totalOrder">
                <div>
                    <div className="title">
                        $ {totalPrice}
                    </div>
                    <div className='costLabel'>
                        <div className='subtitle'>
                            Approximate cost per day
                        </div>
                        <Tooltip 
                            title={'Cost is based on an 8-hour workday'} 
                            placement='right'
                            zIndex={1001}
                            overlayClassName='costTooltip'>
                                <Info className='costIcon' />
                        </Tooltip>
                    </div>
                </div>
                <div>
                    <div className="title">
                        {cart?.length}
                    </div>
                    <div className="subtitle">Team size</div>
                </div>
            </div>
        </>
            : null
    )

};

export default OrderTotalAmount;
