import { useSignOut } from "@nhost/react";
import { Dropdown, Menu } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { useTeam } from "context/TeamContext";

import avatar from 'assets/images/avatar.svg'

import './UserAuthorized.scss';

const UserAuthorized = () => {
    const { user, company, step, project, setExitModalVisible, setExitModalVisibleType, setRedirectPath } = useTeam();
    const navigate = useNavigate();
    const { number } = useParams();

    const { signOut } = useSignOut();

    const goSignOut = () => {
        if (project && step !== 'orderView') {
            setExitModalVisibleType('signOut')
            setExitModalVisible(true)
        } else {
            signOut();
            navigate('/landing')
        }
    };

    const goOrders = () => {
        if (number) {
            navigate('/orders');
        } else if (project && step !== 'orderView') {
            setExitModalVisibleType('session')
            setRedirectPath('/orders')
            setExitModalVisible(true)
        } else {
            navigate('/orders')
        }
    }

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <span>
                            Orders
                        </span>
                    ),
                    onClick: goOrders
                },
                {
                    key: '2',
                    label: (
                        <span>
                            Exit
                        </span>
                    ),
                    onClick: goSignOut
                },
            ]}
        />
    );

    return (
        <Dropdown overlay={menu}>
            <div className="user-authorized">
                <div className="avatar">
                    <img src={avatar} alt="avatar" />
                </div>
                <div className="user-data">
                    <h4>{user?.metadata?.firstName} {user?.metadata?.lastName}</h4>
                    <p>{company?.company_nm}</p>
                </div>
            </div>
        </Dropdown>
    )
};

export default UserAuthorized;
