import gql from "graphql-tag";

export const INSERT_COMPANY = gql`
  mutation insertCompany($company: team_builder_Company_insert_input!) {
  insert_team_builder_Company_one(object: $company) {
    company_id
  }
}
`;

export const GET_COMPANY = gql`
  query MyQuery ($user_id: uuid!){
    team_builder_Company(where: {created_usr_id: {_eq: $user_id}})
    {
      company_id
      company_nm
      }
    }
`;
