import { FC } from "react";
import { Steps } from "antd";
import { useTeam } from "../../context/TeamContext";
import { appSteps } from "variables/app_steps";

import './Steps.scss'

const { Step } = Steps;

const StepsComponent: FC = () => {
    const { step, isSidebar } = useTeam();
    const currentStep = appSteps.indexOf(step);

    return (
        <div className="steps-wrapper" style={{ width: isSidebar ? 'calc(100% - 330px' : '100%' }}>
            <Steps className="steps" current={currentStep}>
                <Step title="About project" />
                <Step title="Team structure" />
                <Step title="Talents" />
                <Step title="Order" />
            </Steps>
        </div>
    )
};

export default StepsComponent;
