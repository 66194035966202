import React, { useContext, useState } from "react";
import { useAuthenticationStatus } from "@nhost/react";
import { EmployeeState, ICompany, INodeType, IRemainRoles, Steps, Team } from "../types";
import { ITechnology } from "pages/EmployeesLibrary/types";

type UseTeamType = () => Team;

const TeamContext = React.createContext<Team>({} as Team);

export const useTeam: UseTeamType = () => {
    return useContext(TeamContext);
}

export const TeamProvider = ({ children }: any) => {
    const { isAuthenticated } = useAuthenticationStatus();
    const [user, setUser] = useState(undefined);
    const [project, setProject] = useState(undefined);
    const [company, setCompany] = useState<ICompany | undefined>(undefined);
    const [employee, setEmployee] = useState<EmployeeState>({
        Emp_Feedbacks: [],
        Emp_educations: [],
        Emp_experiences: [],
        Employee_Grade_Roles: [],
        Employee_Languages: [],
        Employee_Technologies: [],
        Employee_domains: [],
        File_infos: [],
        employee_first_nm: '',
        employee_id: '',
        employee_second_nm: '',
        portfolio_link: null,
        specialization: '',
        total_work_experience: 0,
    });
    const [activeTechnologiesStackByRoles, setActiveTechnologiesStackByRoles] = useState<Record<string, ITechnology[]>>({})
    const [step, setStep] = useState<Steps>('project');
    const [isSteps, setIsSteps] = useState(false);
    const [isFooter, setIsFooter] = useState(false);
    const [isSidebar, setIsSidebar] = useState(false);
    const [scheme, setScheme] = useState(false);
    const [currentHierarchy, setCurrentHierarchy] = useState(undefined);
    const [modalVisible, setModalVisible] = useState(false);
    const [contactUsModalVisible, setContactUsModalVisible] = useState(false);
    const [exitModalVisible, setExitModalVisible] = useState(false);
    const [schemeModalData, setSchemeModalData] = useState(undefined);
    const [removeProjectModalData, setRemoveProjectModalData] = useState(undefined);
    const [sidebarSpinner, setSidebarSpinner] = useState(false);
    const [exitModalVisibleType, setExitModalVisibleType] = useState('session');
    const [redirectPath, setRedirectPath] = useState('');
    const [contactInfo, setContactInfo] = useState({
        comment: '',
        company: '',
        email_to_contact: '',
        name: '',
        phone_to_contact: '',
        surname: '',
    })
    const [agreementModalVisible, setAgreementModalVisible] = useState(false);
    const [resetPasswordFormVisible, setResetPasswordFormVisible] = useState(false);
    const [changePasswordFormVisible, setChangePasswordFormVisible] = useState(false);

    const [visibleModalBusket, setVisibleModalBusket] = useState(false);
    const [arrNodesTeam, setArrNodesTeam] = useState<INodeType[]>([]);
    const [tempNodesTeam, setTempNodesTeam] = useState<INodeType[]>([]);
    const [remainRoles, setRemainRoles] = useState<IRemainRoles[]>([]);

    return (
        <TeamContext.Provider value={{
            isAuthenticated,
            user,
            setUser,
            project,
            setProject,
            company,
            setCompany,
            activeTechnologiesStackByRoles,
            setActiveTechnologiesStackByRoles,
            employee,
            setEmployee,
            step,
            setStep,
            isSteps,
            setIsSteps,
            isFooter,
            setIsFooter,
            isSidebar,
            setIsSidebar,
            currentHierarchy,
            setCurrentHierarchy,
            contactInfo,
            setContactInfo,
            visibleModalBusket,
            setVisibleModalBusket,
            arrNodesTeam,
            setArrNodesTeam,
            tempNodesTeam,
            setTempNodesTeam,
            remainRoles,
            setRemainRoles,
            modalVisible,
            setModalVisible,
            contactUsModalVisible,
            setContactUsModalVisible,
            exitModalVisible,
            setExitModalVisible,
            exitModalVisibleType,
            setExitModalVisibleType,
            schemeModalData,
            setSchemeModalData,
            removeProjectModalData,
            setRemoveProjectModalData,
            sidebarSpinner,
            setSidebarSpinner,
            scheme,
            setScheme,
            redirectPath,
            setRedirectPath,
            agreementModalVisible,
            setAgreementModalVisible,
            resetPasswordFormVisible,
            setResetPasswordFormVisible,
            changePasswordFormVisible,
            setChangePasswordFormVisible
        }}>
            {children}
        </TeamContext.Provider>
    )
}
