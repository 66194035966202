import { useSignOut } from '@nhost/react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import { useTeam } from 'context/TeamContext';

import './ExitModal.scss';

const ExitModal = () => {
    const { exitModalVisibleType, exitModalVisible, setExitModalVisible, redirectPath, setIsSteps } = useTeam();
    const navigate = useNavigate();
    const { signOut } = useSignOut();

    const endSession = () => {
        setExitModalVisible(false)
        setIsSteps(false)
        navigate(redirectPath)
    }

    const handleSignOut = () => {
        signOut();
        navigate('/landing')
    }

    const sendRequest = () => {
        navigate('/order')
        setExitModalVisible(false)
    }

    return (
        <Modal className='exitModal' width={'auto'} closeIcon={<Close />} footer={null} centered visible={exitModalVisible} onCancel={() => { setExitModalVisible(false) }}>
            <div className="exitModal-header">
                {exitModalVisibleType === 'session'
                    ? <h2>Do you want to end the session?</h2>
                    : <h2>Do you want to sign out?</h2>}
            </div>
            <div className="exitModal-content">
                {exitModalVisibleType === 'session'
                    ? <>
                        <p>You will loose order details in this case.</p>
                        <p>If you need any help with the team hiring, feel free to send the request to our manager</p>
                    </>
                    : <p>We will save your order as a draft or you can send your order to a manager now</p>}
            </div>
            <footer className="footer-project">
                <div>
                    {exitModalVisibleType === 'session'
                        ? <button onClick={endSession} className="button-orange button-small">End the session</button>
                        : <button onClick={handleSignOut} className="button-orange button-small">Sign out</button>}
                    <button onClick={sendRequest} className="button-green secondary button-small">Send the request</button>
                </div>
            </footer>
        </Modal>
    )
}

export default ExitModal;