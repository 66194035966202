import { useEffect, useMemo } from 'react';
import { useTeam } from '../../../../../context/TeamContext';

import { useQuery } from '@apollo/client';
import { GET_CART } from 'gql/cart';
import { GET_DEVELOPMENT_TYPE } from 'gql/typeTechnology';
import { GET_PROJECT_TECH_STACK } from 'gql/technologies';

import { Radio, RadioChangeEvent } from 'antd';

import './TeamRecommended.scss';

import { CartType, Technology } from 'types';

type TeamBuilderHierarchyTypeDict = {
   type_id: string;
   type_nm: string;
};
const TeamRecommended = () => {
   const { project, arrNodesTeam, currentHierarchy, setCurrentHierarchy } = useTeam();

   const { data: dataCart, refetch } = useQuery(GET_CART, {
      variables: {
         project_id: project?.project_id,
      },
   });
   const { data: dataDevelopmentTypes } = useQuery(GET_DEVELOPMENT_TYPE);

   const { data: teamBuilderProject } = useQuery(GET_PROJECT_TECH_STACK, {
      variables: {
         project_id: project?.project_id,
      },
   });

   const countTeam = arrNodesTeam.reduce((sum: number, node) => sum + node.count, 0);
   const carts: CartType[] = dataCart?.team_builder_Cart_Order_NEW;
   const defaultCheckedTechnologies = useMemo(() => {
      return teamBuilderProject?.team_builder_Project[0]?.Project_Technologies?.map(
         (activeTech: Technology) => activeTech.Technology_dict.technology_name,
      );
   }, [teamBuilderProject]);

   const developmentType: string = dataDevelopmentTypes?.team_builder_Hierarchy_Type_dict.find(
      (el: TeamBuilderHierarchyTypeDict) => el.type_id === project?.project_type,
   )?.type_nm;

   const getCurrentRoleCount = (id: string) => {
      const count = carts?.filter((cart) => cart?.project_hierarchy_id === id).length;
      return count ?? 0;
   };
   const handleRole = (e: RadioChangeEvent) => {
      const current = arrNodesTeam.find((role) => role.name === e.target.value);
      setCurrentHierarchy(current);
   };

   useEffect(() => {
      if (arrNodesTeam && currentHierarchy) {
         const current = arrNodesTeam.find((role) => role.name === currentHierarchy.name);
         setCurrentHierarchy(current);
      }
      refetch();
   }, []);

   useEffect(() => {
      arrNodesTeam && !currentHierarchy && setCurrentHierarchy(arrNodesTeam[0]);
   }, [arrNodesTeam, currentHierarchy, setCurrentHierarchy]);

   return (
      <div className='teamRecommended'>
         <div className='teamRecommended-header'>
            <div className='header-title'>
               <h3>Team</h3>
               <span>
                  <b>{carts?.length}</b>/{countTeam}
               </span>
            </div>
            <div className='project-info'>
               <h4>{project?.project_nm}</h4>
               <div>
                  Development category: <span>{developmentType}</span>
               </div>
               <div>
                  Project technology stack:{' '}
                  {defaultCheckedTechnologies?.map((technologyName: string) => (
                     <span>{technologyName}</span>
                  ))}
               </div>
            </div>
         </div>
         <div className='team-list'>
            {arrNodesTeam &&
               arrNodesTeam.map((role) => {
                  const currentRoleCount = getCurrentRoleCount(role.id);
                  return (
                     <Radio.Group
                        defaultValue={currentHierarchy?.name || arrNodesTeam[0].name}
                        value={currentHierarchy?.name}
                        onChange={handleRole}
                     >
                        <Radio.Button value={role.name} key={role.id} className='team-item'>
                           <span>{role.name}</span>
                           <div>
                              {project && (
                                 <span>
                                    {currentRoleCount}/{role.count || 0}
                                 </span>
                              )}
                           </div>
                        </Radio.Button>
                     </Radio.Group>
                  );
               })}
         </div>
      </div>
   );
};

export default TeamRecommended;
