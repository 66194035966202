import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Arrow } from 'assets/icons/arrow-left.svg';

import './Breadcrumb.scss';

interface IProps {
    path: string;
    title?: string
}

const BreadCrumb: React.FC<IProps> = ({ path, title }) => {

    return (
        <Link to={path} className='breadcrumb orange'>
            <Arrow />
            {title}
        </Link>
    );
};

export default BreadCrumb;