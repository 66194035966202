import React from 'react';
import avatar from 'assets/images/avatar.svg';

import './Avatar.scss';

interface IProps {
    img?: string
}

const Avatar: React.FC<IProps> = ({ img }) => (
    <div className='user-avatar'>
        <img src={img ?? avatar} alt='avatar' />
    </div>
);

export default Avatar;
