import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { NhostReactProvider } from '@nhost/react';
import { NhostApolloProvider } from '@nhost/react-apollo';
import { nhost } from 'utils/nhost';
import { TeamProvider } from 'context/TeamContext';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <NhostReactProvider nhost={nhost}>
      <NhostApolloProvider nhost={nhost}>
        <TeamProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </TeamProvider>
      </NhostApolloProvider>
    </NhostReactProvider>
    <Toaster />
  </React.StrictMode>
);
