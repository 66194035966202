import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Logo from 'assets/images/logotype.svg';

import { Modal } from 'antd';
import { useTeam } from 'context/TeamContext';
import ResetPassword from './ResetPassword';
import ChangePassword from './ChangePassword';

import './AuthorizationModal.scss';

const AuthorizationModal = () => {
    const { modalVisible, setModalVisible, resetPasswordFormVisible, changePasswordFormVisible } =
        useTeam();
    const [isSignIn, setIsSignIn] = useState(true);

    const location = useLocation();
    const isVerificationEmailSuccess = location.search.includes('?verificationsuccess');

    const wrapModal = () => {
        setIsSignIn(!isSignIn);
    };

    useEffect(() => {
        if (isVerificationEmailSuccess) {
            setIsSignIn(false);
        }
    }, [])

    return (
        <Modal
            className="auth-modal"
            width={'fit-content'}
            footer={null}
            centered
            bodyStyle={{
                display:'flex',
                alignItems: 'center',
            }}
            visible={modalVisible}
            onCancel={() => {
                setModalVisible(false);
            }}
        >
            <div className="wrapper-sign">
                <div className="logotype">
                    <img src={Logo} alt="Enfint" />
                </div>
                <div className="sign-form">
                    <h1>
                        {isSignIn
                            ? resetPasswordFormVisible
                                ? 'RESET PASSWORD'
                                : 'SIGN IN'
                            : 'SIGN UP'}
                    </h1>
                    {isSignIn ? (
                        changePasswordFormVisible ? (
                            <ChangePassword />
                        ) : resetPasswordFormVisible ? (
                            <ResetPassword />
                        ) : (
                            <SignIn wrapModal={wrapModal} />
                        )
                    ) : (
                        <SignUp
                            wrapModal={wrapModal}
                            isVerificationEmailSuccess={isVerificationEmailSuccess}
                        />
                        
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AuthorizationModal;