import SuspenseLoader from "components/SuspenseLoader";
import { useTeam } from "context/TeamContext";

import './SidebarSpinner.scss';

const SidebarSpinner = () => {
    const { sidebarSpinner } = useTeam();

    return (
        <>
            {sidebarSpinner && <div className="sidebarSpinner" style={{
                height: `100vh`,
                width: `100vw`,
                top: '80px',
                left: '0px',
            }} >
                <SuspenseLoader />
            </div>}
        </>
    )
}

export default SidebarSpinner