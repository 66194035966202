import { Modal } from 'antd';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import { useTeam } from 'context/TeamContext';

import './AgreementModal.scss';
import { PrivacyPolicyText } from 'components/PrivacyPolicyText';

export const AgreementModal = () => {
    const { agreementModalVisible, setAgreementModalVisible } = useTeam();

    return (
        <Modal className="AgreementModal" width='50%' closeIcon={<Close />} footer={false} visible={agreementModalVisible} onCancel={() => setAgreementModalVisible(false)} centered>
            <div className="AgreementModal-header">
                <h1>Privacy Statement</h1>
            </div>
            <div className='AgreementModal-main'>
                <PrivacyPolicyText />
            </div>
            <footer className='AgreementModal-footer'>
                <button onClick={() => setAgreementModalVisible(false)} className="button-white button-small">Close</button>
            </footer>
        </Modal>
    )
}