import gql from "graphql-tag";

export const S_USER_GET_SELF = gql`
  subscription s_userGetSelf($id: uuid!) {
    users_by_pk(id: $id) {
      id
      display_name
      avatar_url
      account {
        email
      }
    }
  }
`;

export const GET_USER_QUERY = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation ($id: uuid!, $displayName: String!, $metadata: jsonb) {
    updateUser(pk_columns: { id: $id }, _set: { displayName: $displayName, metadata: $metadata }) {
      id
      displayName
      metadata
    }
  }
`;
