import React, { useEffect, useState } from "react";
import { useAccessToken } from "@nhost/react";
import { useTeam } from "context/TeamContext";

import { useMutation, useQuery } from "@apollo/client";
import { Divider } from "antd";

import { GET_CART, UPDATE_STATUS_CART } from "gql/cart";

import Tag from "components/Tag";
import Avatar from "components/Avatar";
import { CART_STATUS_ID } from "variables/cart";
import { pictureProcessing } from "utils/pictureProcessing";

import { CartType, EmployeeType } from "types";

interface IUserOrderProps {
    cart: CartType[],
    view?: boolean,
}

interface IUserOrderItemProps {
    Employee: EmployeeType;
    cart_id: string;
    category: string;
    view: boolean;
}

interface IUserOrderCategory {
    category: string;
    list: IList[];
    view: boolean;
}

interface IList extends CartType {
    order_id?: number;
}

export const UserOrderItem = ({ Employee, cart_id, category, view }: IUserOrderItemProps) => {
    const token = useAccessToken();
    const { project } = useTeam();
    const [avatar, setAvatar] = useState<string>()

    const [updateStatusCart] = useMutation(UPDATE_STATUS_CART);

    const { refetch } = useQuery(GET_CART, {
        variables: {
            project_id: project?.project_id
        }
    });

    const info = Employee?.Employee_Grade_Roles[0].Grade_Role_matrix
    const currentRole = Employee?.Employee_Grade_Roles.find((item) => item.Grade_Role_matrix.Role_dict.role_nm === category)
    const files = Employee?.File_infos
    const price = currentRole?.Grade_Role_matrix?.price ?? info?.price
    const specialization = Employee?.specialization
    const name = `${Employee?.employee_first_nm} ${Employee?.employee_second_nm[0]}.`

    const removeFromCart = () => {
        updateStatusCart({
            variables: {
                cart_id: cart_id,
                status: CART_STATUS_ID.CANCELED
            }
        }).finally(() => refetch())
    }

    useEffect(() => {
        (async () => {
            const avatarFile = files?.find((file) => file.File_type_dict.type_nm === 'photo')
            if (avatarFile) {
                const blob = await pictureProcessing(token, avatarFile.file_id);
                const avatar = URL.createObjectURL(blob)

                setAvatar(avatar)
            }
        })()
    }, [files, token])

    return (
        <div className="teamOrder-item custom">
            <Avatar img={avatar} />
            <div className="item-info">
                <span>
                    <b>{specialization}</b>
                </span>
                <span className="item-info-name">{name}</span>
            </div>
            <Tag title={`$ ${price} hr`} fontColor='#006A56' color="e5f0ee" />
            {!view && <button className="button-red link" onClick={removeFromCart}>Remove</button>}
        </div>
    )
}

const UserOrderCategory = ({ category, list, view }: IUserOrderCategory) => {
    const { arrNodesTeam } = useTeam()
    const totalAmount = arrNodesTeam.find((node) => node.name === category)?.count
    return (
        <div className="teamOrder-category">
            <div className="teamOrder-category-header">
                <div className="title">
                    <span>{category}</span>
                    <span><span>{list.length}</span>/{totalAmount}</span>
                </div>
                <Divider />
            </div>
            {list.map((employee) => {
                const key = employee.Employee?.employee_id
                return employee.Employee && <UserOrderItem key={key} category={category} view={view} {...employee} />
            })}
        </div>
    )
}

const UserOrder: React.FC<IUserOrderProps> = ({ cart, view }) => {
    const { arrNodesTeam } = useTeam();
    let collection: Record<string, CartType[]> = {}

    for (let node of arrNodesTeam) {
        let role = node.name
        const inCart = cart.filter((el) => el.Project_Hierarchy_NEW.Role_dict.role_nm === role)
        collection[role] = inCart
    }

    let categories: any = Object.keys(collection)

    return categories.sort((a: string, b: string) => collection[b].length - collection[a].length).map((category: string) => <UserOrderCategory key={category} category={category} list={collection[category]} view={!!view} />)
};

export default UserOrder;
