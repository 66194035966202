import React from "react";
import { Layout } from "antd";
import { useTeam } from "context/TeamContext";

import TeamRecommended from "layouts/SidebarLayout/Sidebar/components/TeamRecommended";
import BreadCrumb from "components/Breadcrumb";

import bgimage from 'assets/images/projectPage_image.svg'
import bgunion from 'assets/images/projectPage_union.svg'

import './Sidebar.scss'

const { Sider } = Layout;

const Sidebar: React.FC = () => {
    const { step } = useTeam();

    return (
        <Sider className="sidebar" width={330}>
            {step === 'project' && <div className="sidebar-project">
                <img src={bgimage} alt='bg-img' className="bg-img" />
                <img src={bgunion} alt='bg-union' className="bg-union" />
                <p>Help us to understand your needs better. Add a few details about the project. Fields marked with "<span className="required">*</span>" are required to be filled in</p>
            </div>}
            {(step === 'employees' || step === 'order') &&
                <TeamRecommended />
            }
            {step === 'employee' &&
                <BreadCrumb path="/employees" title="Staff" />
            }
        </Sider>
    )
};

export default Sidebar;
