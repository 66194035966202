import { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Layout } from "antd";
import { getArrNodes } from "utils/arr_nodes";
import { useTeam } from "context/TeamContext";

import { GET_PROJECT_HIERARCHY_NEW } from "gql/projectHierarchy";
import Sidebar from "./Sidebar";
import ContactUsModal from "components/ContactUsModal";
import ExitModal from "components/ExitModal";
import Footer from "components/Footer";
import SidebarSpinner from "./SidebarSpinner";
import { RoleType } from "types";

const { Content } = Layout;

const SidebarLayout = () => {
    const { project, setScheme, setProject, setArrNodesTeam, isFooter, isSidebar } = useTeam();

    const { data: dataProjectHierarchy } = useQuery(GET_PROJECT_HIERARCHY_NEW, {
        variables: {
            project_id: project?.project_id
        }
    });
    const projectHierarchy: RoleType[] = useMemo(() => dataProjectHierarchy?.team_builder_Project_Hierarchy_NEW, [dataProjectHierarchy?.team_builder_Project_Hierarchy_NEW]) 

    useEffect(() => {
        if (dataProjectHierarchy) {
            setArrNodesTeam(getArrNodes(projectHierarchy))
            setScheme(true)
        } else {
            setScheme(false)
        }
    }, [dataProjectHierarchy, project])

    useEffect(() => {
        return () => {
            setProject(undefined)
        }
    }, [])

    return (
        <Layout hasSider
            style={{ marginTop: '80px' }}
        >
            <Content style={isSidebar ? { marginLeft: '330px', minHeight: 'calc(100vh - 80px)' } : {}}>
                {isSidebar && <Sidebar />}
                <Outlet />
                {isFooter && <Footer />}
                <SidebarSpinner />
            </Content>
            <ContactUsModal />
            <ExitModal />
        </Layout>
    )
};

export default SidebarLayout;
