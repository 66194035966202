import { FC } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { GET_CART, UPDATE_STATUS_CART } from "gql/cart";
import { CART_STATUS_ID } from "variables/cart";
import UserOrder from "components/TeamOrder/components/UserOrder";
import DefaultOrder from "./components/DefaultOrder";

import './TeamOrder.scss'
import { useTeam } from "context/TeamContext";
import { CartType } from "types";

interface IProps {
    cart: CartType[],
    view?: boolean
}

const TeamOrder: FC<IProps> = ({ cart, view }) => {
    const { project } = useTeam();
    const [updateStatusCart] = useMutation(UPDATE_STATUS_CART);

    const { refetch } = useQuery(GET_CART, {
        variables: {
            project_id: project?.project_id
        }
    });

    const removeAll = () => {
        const request = cart.map(el => updateStatusCart({
            variables: {
                cart_id: el.cart_id,
                status: CART_STATUS_ID.CANCELED
            }
        }))
        Promise.all(request).finally(() => refetch())
    }

    const userOrder = cart?.length > 0 && cart.find(el => el.Employee)

    return (
        <div className="teamOrder">
            <div className="teamOrder-header">
                <h2>{cart?.length > 0 ? 'Team' : 'Roles'}</h2>
                {cart?.length > 0 && !view && <button className="button-green link" onClick={removeAll}>Remove all</button>}
            </div>
            <div className="teamOrder-list">
                {userOrder
                    ? <UserOrder cart={cart} view={view} />
                    : <DefaultOrder />}
            </div>
        </div>
    )
};

export default TeamOrder;
