import { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import UserAuthorized from './UserAuthorized';
import { useTeam } from 'context/TeamContext';
import StepsComponent from 'components/Steps';

import logo from 'assets/images/logo.svg';

import './Header.scss';

const Header = () => {
    const {
        isAuthenticated,
        step,
        setModalVisible,
        project,
        setExitModalVisible,
        setExitModalVisibleType,
        setChangePasswordFormVisible,
    } = useTeam();
    const navigate = useNavigate();
    const { number } = useParams();
    
    const location = useLocation();
    const isStepsVisible =
        location.pathname === '/project' ||
        location.pathname === '/structure' ||
        location.pathname === '/employees' ||
        location.pathname === '/order';
        
    const onLogoClick = () => {
        if (number) {
            navigate('/landing');
        } else if (project && step !== 'orderView') {
            setExitModalVisibleType('session');
            setExitModalVisible(true);
        } else {
            navigate('/landing');
        }
    };

    useEffect(() => {
        if (location.search.includes('resetpassword')) {
            setModalVisible(true);
            setChangePasswordFormVisible(true);
        }

        if (location.search.includes('verificationsuccess')) {
            setModalVisible(true);
        }

    }, [])

    return (
        <header className="header">
            <div className="img-wrapper">
                <img onClick={onLogoClick} src={logo} alt="logo" />
            </div>
            {isStepsVisible && <StepsComponent />}
            {isAuthenticated ? (
                <UserAuthorized />
            ) : (
                <button
                    className="button-white"
                    onClick={() => setModalVisible(true)}
                >
                    Sign In
                </button>
            )}
        </header>
    );
};

export default Header;
