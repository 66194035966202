export const formatPhoneNumber = (value: string) => {
    if (!value) {
        return value;
    }
    const phoneNumber = value.includes('+') ? value.slice(2).replace(/[^\d]/g, '') : value.replace(/[^\d]/g, '');
    if (phoneNumber === '8') {
        return '+7';
    }
    if (phoneNumber.length < 4) {
        return `+7 ${phoneNumber}`;
    }
    if (phoneNumber.length < 7) {
        return `+7 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `+7 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3,
        6)}-${phoneNumber.slice(6,10)}`;
}

export const formatCompany = (value: string) => {
    if (!value) {
        return value;
    }
    return value.replace(/[^а-яА-Яa-zA-z0-9\s]*/g, '');
}

export const formatName = (value: string) => {
    if (!value) {
        return value;
    }
    return value.replace(/[^а-яА-Яa-zA-z\s]*/g, '');
}

export const validatePassword = (value: string) => {
    const bigCharacters = /[A-Z]+/.test(value);
    const lengthString = value.length >= 8 && value.length <= 14;
    const numberCharacters = /[0-9]+/.test(value);
    const res = bigCharacters && lengthString && numberCharacters;
    return res;
}
